/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable import/named */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable dot-notation */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, Modal } from '@Components';
import { resolveMapping } from '@Constants';
import { useParams } from 'react-router';
import {
  setSelectedRule,
  setNewRuleOpen,
  setUpdateRuleOpen,
  setDisabledButton,
  setSaveAsButtonStatus,
  getPreviewProductsRequest,
  addRuleRequest,
  setDraftConditionsData,
  setDraftNameData,
  setDraftModeDisabled,
  getProductsRequest,
  getRuleInformationRequest,
} from '../../store/auth';
import {
  setFormSubmitSaveButton,
} from '../../store/category';
import { FormContext } from './FormContext';
import Element from './components/Element';

const getPath = (id) => id.split('/');

const setValue = (data, paths, value) => {
  let obj = data;
  const path = paths[0];
  if (paths.length === 0) {
    obj.field_value = value;
  } else if (obj.field_id === path) {
    obj = setValue(obj, paths.slice(1), value);
  } else {
    obj = setValue(obj[path], paths.slice(1), value);
  }
  return obj;
};

const addValue = (data, paths, value) => {
  const path = paths[0];
  if (paths.length === 0) {
    data.fields.push(value);
  } else if (data.field_id === path) {
    data.fields.push(value);
  } else {
    data = addValue(data[path], paths.slice(1), value);
  }
  return data;
};

const removeValue = (data, paths, index) => {
  const path = paths[0];
  if (paths.length === 0) {
    data = data.slice(0, index).concat(data.slice(index + 1));
  } else if (data.field_id === path) {
    data.fields = data.fields.slice(0, index).concat(data.fields.slice(index + 1));
  } else {
    data = removeValue(data[path], paths.slice(1), index);
  }
  return data;
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#000',
    color: '#fff',
    minHeight: 'auto',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {},
  formControl: {
    color: '#fff',
    backgroundColor: '#333333',
    '& > .MuiOutlinedInput-root > .MuiSelect-selectMenu': {
      padding: '0 25px 0 10px',
      height: 40,
      display: 'flex',
      alignItems: 'center',
    },
    '& > .MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& > .MuiButton-containedSecondary': {
      height: 48,
    },
    '& > .MuiButtonBase-root > .MuiButton-label': {
      display: 'block',
    },
    '& > .MuiButtonBase-root > .MuiButton-label > .MuiSvgIcon-root': {
      display: 'block',
      margin: 'auto',
    },
  },
  tableInput: {
    marginLeft: '10px',
    marginBottom: '10px',
  },
  submitBtn: {
    background: '#34b200',
    color: '#fff',
    borderRadius: 0,
    height: '53px',
    boxShadow: 'none',
    '&:hover': {
      background: '#34b200',
      opacity: 0.9,
    },
  },
  removeButton: {
    color: '#fff',
  },
  successBtn: {
    color: '#fff',
    background: '#00B200',
    fontSize: '12px',
    borderRadius: '0',
  },
  previewButton: {
    color: '#fff',
    background: '#00B2A4',
    fontSize: '12px',
    borderRadius: '0',
  },
  saveAsButton: {
    color: '#fff',
    background: '#278301',
    fontSize: '12px',
    borderRadius: '0',
  },
}));
function App({
  onSubmit, fields, deleteButton, handleDeleteRule,
}) {
  const dispatch = useDispatch();
  const classes = useToolbarStyles();
  const [elements, setElements] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openSaveModal, setOpenSaveModal] = React.useState(false);
  const { categoryId } = useParams();
  const {
    ruleDetailPending,
    saveAsButtonStatus,
    rules,
    selectedRule,
    rowsPerPage,
    selectedCategory,
    draftMode,
    isMarketplace,
    productPage,
  } = useSelector((state) => state.auth);

  const {
    formSubmitSaveButton,
    formRastUpdateChecboxStatus,
  } = useSelector((state) => state.category);

  const rulesCategorySelected = rules?.filter((v) => (v.id === selectedRule ? v.name : ''));
  useEffect(() => {
    setElements({
      fields,
    });
  }, [fields]);

  useEffect(() => {
    if(formSubmitSaveButton) {
      setOpenSaveModal(true);
      dispatch(setFormSubmitSaveButton(false));
    }
  }, [formSubmitSaveButton]);

  const handleSubmit = () => {
    // event.preventDefault();
    onSubmit(elements);
    dispatch(setNewRuleOpen(false));
    dispatch(setUpdateRuleOpen(false));
  };

  const nameSaveAsElement = elements?.fields?.find((fields) => fields.field_id === 'name');
  const setSaveAsElement = elements?.fields?.filter((fields) => fields.field_id === 'conditions');
  const handlePreviewSubmit = () => {
    dispatch(setDraftNameData(nameSaveAsElement?.field_value || ''));
    dispatch(setDraftConditionsData(setSaveAsElement));
    dispatch(getPreviewProductsRequest({
      params: {
        id: categoryId,
        limit: rowsPerPage,
        ...resolveMapping({ fields: setSaveAsElement }),
      },
    }));
  };

  const handleChange = (id, value) => {
    const newElements = JSON.parse(JSON.stringify({ ...elements }));
    newElements?.fields?.forEach((field) => {
      const paths = getPath(id);
      if (paths[0] === field.field_id) {
        field = setValue(field, paths.slice(1), value);
      }
    });
    const element = document.querySelector('.rule-form-content');
    const elementScrollTop = element.scrollTop;
    setElements(newElements);
    element.scrollTop = elementScrollTop;
  };

  const handleAddItem = (id, schema) => {
    const newElements = JSON.parse(JSON.stringify({ ...elements }));
    newElements.fields.forEach((field) => {
      const paths = getPath(id);
      if (paths[0] === field.field_id) {
        field = addValue(field, paths.slice(1), schema);
      }
    });
    setElements(newElements);
  };

  const handleRemoveItem = (id, index) => {
    const newElements = JSON.parse(JSON.stringify({ ...elements }));
    newElements.fields.forEach((field) => {
      const paths = getPath(id);
      if (paths[0] === field.field_id) {
        if (paths.slice(1).length === 1) {
          field.fields = field.fields.slice(0, index).concat(field.fields.slice(index + 1));
        } else {
          field = removeValue(field, paths.slice(1), index);
        }
      }
    });
    setElements(newElements);
  };
  const rulesAddOpenModal = () => {
    dispatch(setNewRuleOpen(true));
    dispatch(setUpdateRuleOpen(false));
  };

  const rulesChangeHandleSelect = async (value) => {
    if (value === selectedCategory?.sorting_rule_id) {
      dispatch(setDisabledButton(true));
    } else {
      dispatch(setDisabledButton(false));
    }
  };

  const rulesSelectChange = (value) => {
    if (value === '0') {
      rulesAddOpenModal();
      localStorage.setItem('rulesAdd', 0);
      dispatch(setSaveAsButtonStatus(true));
    } else {
      dispatch(setSelectedRule(value));
      dispatch(setNewRuleOpen(false));
      dispatch(setUpdateRuleOpen(true));
      localStorage.setItem('rulesAdd', 1);
      dispatch(setSaveAsButtonStatus(false));
    }
  };

  const setOpenModalControl = () => {
    if (localStorage.getItem('rulesAdd') === '0') {
      handleSubmit();
    } else {
      setOpenSaveModal(true);
    }
  };

  const draftModeDisabled = () => {
    dispatch(setDraftModeDisabled(false));
    if (selectedRule) {
      dispatch(getRuleInformationRequest({
        data: {
          id: selectedRule,
        },
      }));
      dispatch(getProductsRequest({
        params: {
          id: categoryId,
          limit: rowsPerPage,
          page: parseFloat(productPage === 0 ? 1 : productPage),
          mp: isMarketplace,
          sorting_rule_id: selectedRule,
        },
      }));
    }
  };

  const setSaveAsButton = () => {
    const datas = {
      ...resolveMapping(elements),
    };
    if(formRastUpdateChecboxStatus) {
      datas.category_id = categoryId;
    }
    dispatch(addRuleRequest({
      data: datas
    }));
    dispatch(setNewRuleOpen(false));
    dispatch(setUpdateRuleOpen(false));
  };

  // useEffect(() => {
  //   console.log(elements);
  // }, [elements]);

  return (
    <FormContext.Provider value={{ handleChange, handleAddItem, handleRemoveItem }}>
      <div
        className="App container"
        style={{
          display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '600px', height: '100vh',
        }}
      >
        <div
          className=""
          style={{
            background: 'transparent linear-gradient(90deg, #8A5B92 0%, #477FB7 58%, #086AAC 100%) 0% 0% no-repeat', color: '#fff', padding: '0 21px', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
          }}
        >
          <FormControl
            className={classNames('table-head-select ', classes.formControl, classes.select)}
          >
            <Typography variant="body1">
              Rules Name:
            </Typography>
            <Select
              labelId="typesLabel"
              label="Types"
              input={<OutlinedInput classes={{ input: classes.input }} />}
              value={selectedRule}
              style={{ color: '#fff' }}
              disabled={draftMode}
              onChange={(e) => {
                rulesChangeHandleSelect(e.target.value);
                rulesSelectChange(e.target.value);
              }}
            >
              <MenuItem value="0" aria-label="rule add">
                Add New
              </MenuItem>

              {rules?.map((field) => (
                <MenuItem
                  key={field.id}
                  value={field.id}
                  checked={field.id === selectedRule}
                >
                  {field.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {deleteButton && (
            <Button type="button" onClick={() => setOpenModal(true)} className={classes.removeButton}>
              Sil
              {' '}
              <DeleteForeverIcon fontSize="small" />
            </Button>
          )}
        </div>
        {ruleDetailPending ? (
          <Loader />
        ) : (
          <form
            className="rule-form-content"
            style={{
              padding: 20,
              // -webkit-flex-grow: 1;
              // -ms-flex-positive: 1;
              flexGrow: 1,
              minHeight: 0,
              overflow: 'auto',
            }}
          >
            {elements?.fields?.map((field, i) => field.field_label !== 'conditionSummary' && <Element key={i.toString()} {...field} />)}
          </form>
        )}

        <div
          className=""
          style={{ display: 'flex', width: '100%' }}
        >

          <Button variant="contained" type="button" color="default" className={classes.previewButton} aria-label="rule edit" onClick={handlePreviewSubmit} style={{ flex: '1 0 0%', display: saveAsButtonStatus ? 'none' : '' }}>
            Formül Önizleme
          </Button>
          {!draftMode && (
            <>
              <Button variant="contained" type="button" color="default" className={classes.successBtn} onClick={setOpenModalControl} style={{ flex: '1 0 0%' }}>
                Kaydet
              </Button>

              <Button variant="contained" type="button" color="default" className={[classes.saveAsButton]} onClick={setSaveAsButton} style={{ flex: '1 0 0%', display: saveAsButtonStatus ? 'none' : '' }}>
                Farklı Kaydet
              </Button>
            </>
          )}

          {draftMode && (
          <>
            <Button variant="contained" type="button" color="default" className={classes.successBtn} onClick={draftModeDisabled} style={{ flex: '1 0 0%' }}>
              Önizleme Modundan Çık
            </Button>
          </>
          )}

        </div>
      </div>
      {selectedRule && rulesCategorySelected && (
        <Modal open={openModal} setOpen={setOpenModal} callback={handleDeleteRule} buttonTitle="Sil" description={`${rulesCategorySelected[0]?.name} Rules Silinecek Emin misiniz?`} />
      )}
      <Modal open={openSaveModal} setOpen={setOpenSaveModal} callback={handleSubmit} buttonTitle="Devam Et" description="Tanımlı olan tüm kategorilerde uygulanacaktır. Emin misiniz?" />
    </FormContext.Provider>
  );
}

App.displayName = 'Nested Form';

export default App;
