/* eslint-disable import/no-cycle */
import { axiosInstance } from './index';

const handlerEnabled = false;

// getLogin
const getLogin = async (data) => {
  try {
    const response = await axiosInstance.post('/api/auth/generateToken', data, { handlerEnabled });
    return response;
  } catch (error) {
    return {
      data: {
        success: false,
        message: JSON.stringify(error),
      },
    };
  }
};

// getCategories
const getCategories = async (data, params) => {
  const response = await axiosInstance.post('/api/category/list', data, { params, handlerEnabled });
  return response;
};

// addRule
const addRule = async (data, params) => {
  const response = await axiosInstance.post('/api/rule/add', data, { params, handlerEnabled });
  return response;
};

// updateRule
const updateRule = async (data, params) => {
  const response = await axiosInstance.post('/api/rule/update', data, { params, handlerEnabled });
  return response;
};

// deleteRule
const deleteRule = async (data, params) => {
  const response = await axiosInstance.post('/api/rule/delete', data, { params, handlerEnabled });
  return response;
};

// getRuleFields
const getRuleFields = async (data, params) => {
  const response = await axiosInstance.post('/api/rule/fields', data, { params, handlerEnabled });
  return response;
};

// updateCategoryRule
const updateCategoryRule = async (data, params) => {
  const response = await axiosInstance.post('/api/category/rule', data, { params, handlerEnabled });
  return response;
};

// getCategories
const getProducts = async (data, params) => {
  const response = await axiosInstance.post('/api/category/product', data, { params, handlerEnabled });
  return response;
};

// getPreviewCategories
const getPreviewProducts = async (data, params) => {
  const response = await axiosInstance.post('/api/category/product', data, { params, handlerEnabled });
  return response;
};

const unsetCustomOrder = async (data, params) => {
  const response = await axiosInstance.post('/api/category/unset', data, { params, handlerEnabled });
  return response;
};

const unsetAllCustomOrder = async (data, params) => {
  const response = await axiosInstance.post('/api/category/unsetall', data, { params, handlerEnabled });
  return response;
};

// getRuleInformation
const getRuleInformation = async (data, params) => {
  const response = await axiosInstance.post('/api/rule/view', data, { params, handlerEnabled });
  return response;
};

// updateProductOrderFirst
const updateProductOrderFirst = async (data, params) => {
  const response = await axiosInstance.post('/api/category/first', data, { params, handlerEnabled });
  return response;
};

// updateProductOrderLast
const updateProductOrderLast = async (data, params) => {
  const response = await axiosInstance.post('/api/category/last', data, { params, handlerEnabled });
  return response;
};

// updateProductOrderWithIndex
const updateProductOrderWithIndex = async (data, params) => {
  const response = await axiosInstance.post('/api/category/index', data, { params, handlerEnabled });
  return response;
};

// getRules
const getRules = async (data, params) => {
  const response = await axiosInstance.post('/api/rule/list', data, { params, handlerEnabled });
  return response;
};

// getRuleView
const getRuleView = async (data, params) => {
  const response = await axiosInstance.post('/api/rule/view', data, { params, handlerEnabled });
  return response;
};

// getCategoryImport
const getCategoryImport = async (data, params) => {
  const response = await axiosInstance.post('/api/category/import', data, { params, handlerEnabled });
  return response;
};
// getCategoryExport
const getCategoryExport = async (data, params) => {
  const response = await axiosInstance.post('/api/category/export', data, { params, handlerEnabled });
  return response;
};

// SiteId
const getSiteId = async (data, params) => {
  const response = await axiosInstance.post('api/auth/switch', data, { params, handlerEnabled });
  return response;
};

// getRulequeue
const getRulequeue = async (data, params) => {
  const response = await axiosInstance.post('/api/rule/queue', data, { params, handlerEnabled });
  return response;
};

// getSites
const getSites = async (data, params) => {
  const response = await axiosInstance.post('/api/sites/list', data, { params, handlerEnabled });
  return response;
};

export default {
  getLogin,
  getCategories,
  getRules,
  getSites,
  getRuleView,
  getProducts,
  getPreviewProducts,
  getRuleInformation,
  getCategoryImport,
  getCategoryExport,
  updateProductOrderWithIndex,
  updateProductOrderFirst,
  updateProductOrderLast,
  updateCategoryRule,
  addRule,
  updateRule,
  deleteRule,
  unsetCustomOrder,
  unsetAllCustomOrder,
  getSiteId,
  getRuleFields,
  getRulequeue,
};
