import React from 'react';
import RuleAdd from './Rule/Add/Add';

const NotFound = React.lazy(() => import('./NotFound/NotFound'));
const Login = React.lazy(() => import('./Login/Login'));
const Home = React.lazy(() => import('./Home/Home'));
const Category = React.lazy(() => import('./Category/Category'));
const RuleEdit = React.lazy(() => import('./Rule/Edit/Edit'));

export {
  NotFound,
  Home,
  Category,
  Login,
  RuleEdit,
  RuleAdd,
};
