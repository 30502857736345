/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import axios from 'axios';
import config from '@Config';
import Cookie from 'js-cookie';
import {
  requestHandler,
  successHandler,
  errorHandler,
} from '../interceptors';

const url = window.location.href;
let baseURL;

if (url.includes('sneakerbox')) {
  baseURL = 'https://panel-sorting.sneakerbox.ru/'
} else {
  baseURL = config?.BASE_URL
}

// add your BASE_URL to Constants file
export const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    platform: 'web',
    'BUTTERFLY-API-KEY': (Cookie.get('api_key')),
    'BUTTERFLY-ACCESS-TOKEN': (Cookie.get('token')),
  },
});

// Handle request process
axiosInstance.interceptors.request.use((request) => requestHandler(request));
// Handle response process
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

export const axiosDefault = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    platform: 'web',
    'BUTTERFLY-API-KEY': (Cookie.get('api_key')),
    'BUTTERFLY-ACCESS-TOKEN': (Cookie.get('token')),
  },
});

// Handle request process
axiosDefault.interceptors.request.use((request) => requestHandler(request));
// Handle response process
axiosDefault.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

export const checkAxiosInstance = () => {
  axiosInstance.interceptors.request.use((config) => {
    config.headers = {
      ...config.headers,
      ...((() => ({
        'BUTTERFLY-API-KEY': (Cookie.get('api_key')),
        'BUTTERFLY-ACCESS-TOKEN': (Cookie.get('token')),
      }))()),
    };
    return config;
  });
};
