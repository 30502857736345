/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import auth from './auth';
import category from './category';
import loading from './loading';

const reducer = combineReducers({
  auth,
  category,
  loading,
});

const store = configureStore({
  reducer,
});

export default store;
