import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import {
  IconLoading,
} from '@Icons';
import {
  setFormSubmitSaveButton,
} from '../../store/category';

const SaveButton = () => {
  const { loading } = useSelector((state) => state.loading);
  const dispatch = useDispatch();

  return loading ? (
    <Button
      className="save-button"
      disabled
    >
      <span style={{ transform: 'scale(0.5)' }}>
        <IconLoading />
      </span>
    </Button>
  ) : (
    <Button
      className="save-button"
      onClick={() => dispatch(setFormSubmitSaveButton(true))}
    >
      Kaydet
    </Button>
  );
};

export default SaveButton;
