import React from 'react';
import './Loader.scss';
import {
  IconLoading,
} from '@Icons';

const Loader = () => (
  <div className="flex items-center justify-center" style={{ minHeight: '100vh' }}>
    <IconLoading />
  </div>
);

Loader.displayName = 'Loader';

export default Loader;
