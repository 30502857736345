/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { FormContext } from '../../FormContext';

const Checkbox = ({
  field_id, field_label, field_value, key,
}) => {
  const { handleChange } = useContext(FormContext);

  return (
    <div className="mb-3 form-check">
      <input
        key={key}
        type="checkbox"
        className="form-check-input"
        id="exampleCheck1"
        defaultChecked={field_value}
        onChange={(event) => handleChange(field_id, event.target.checked)}
      />
      <label className="form-check-label" htmlFor="exampleCheck1">{field_label}</label>
    </div>
  );
};

export default Checkbox;
