import {
  createSlice,
} from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loading: false,
};

const slice = createSlice({
  name: 'loading',
  initialState: INITIAL_STATE,
  reducers: {
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {

  },
});

export default slice.reducer;

export const {
  setLoader,
} = slice.actions;
