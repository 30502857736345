/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import history from '@History';
import { useSelector } from 'react-redux';
import {
  ErrorBoundary,
  Loader,
} from '@Components';
import {
  Login,
} from '@Pages';
import './App.scss';
import 'lazysizes/plugins/attrchange/ls.attrchange';

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

function ThemeApp() {
  const { token } = useSelector((state) => state.auth);

  return (
    <ErrorBoundary>
      <Router history={history}>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/login" name="Login" render={(props) => (token ? <Redirect to="/home" /> : <Login {...props} />)} />
            <Route path="/" name="Home" render={(props) => (token ? <DefaultLayout {...props} /> : <Redirect to="/login" />)} />
          </Switch>
        </Suspense>
      </Router>
    </ErrorBoundary>
  );
}

export default ThemeApp;
