import { dev } from './dev';
import { prod } from './prod';
import { preprod } from './preprod';

const conf = {
  dev,
  prod,
  preprod,
};

export default {
  ...conf[process.env.REACT_APP_STAGE],
};
