/* eslint-disable react/no-array-index-key */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import React, { useContext, useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import API from '@API';
import Element from '../Element';
import { FormContext } from '../../FormContext';
import editIcon from '../../../../assets/edit-icon.svg';
import deleteIcon from '../../../../assets/delete-icon.svg';
import {useDispatch, useSelector} from "react-redux";
import {
  setFormRastUpdateChecboxStatus,
} from '../../../../store/category';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {setUpdateRuleOpen} from "../../../../store/auth";
import { SaveButton } from '@Components';



const Nested = ({ fields, schema, field_label, field_id, field_type, parent, conditionSummaryList }) => {
  const dispatch = useDispatch();
  const { handleAddItem, handleRemoveItem } = useContext(FormContext);
  const [indexes, setIndexes] = useState([]);
  const [currentTab, setCurrentTab] = useState(null);
  const [conditionSummaryNewList, setConditionSummaryNewList] = useState(conditionSummaryList);
  const {
    formSubmitSaveButton,
    formRastUpdateChecboxStatus
  } = useSelector((state) => state.category);
  const handleTabClick = (e) => {
    if (currentTab === e.target.id) {
      setCurrentTab(null);
    }else {
      setCurrentTab(e.target.id);
    }
  }

  const getIndexNumber = (index) => {
    if (fields.length < indexes.length) {
      setIndexes([]);
    }
    if (typeof indexes[index] === 'undefined') {
      const ins = [...indexes];
      const r = Math.random();
      ins.push(r);
      setIndexes(ins);
      return r;
    }
    return indexes[index];
  };

  const handleRemoveCondition = (field_id, index) => {
    handleRemoveItem(`${field_id}/fields`, index);
    const conditionTitleObject = conditionSummaryNewList.filter((v,i) => i != index);
    setConditionSummaryNewList(conditionTitleObject);
  }



  const ConditionsDetail = ({row,index}) => {
    return (
      <Paper
        className={`fields-container`}
        elevation={3}
        key={String(
          `${field_id}/fields/${Math.random()}/${index}`,
        )}
        style={{padding: 20, marginBottom: 20}}
      >
        <Typography variant="h6" component="h2" className="condition-titie">
          {`${field_label} ${index + 1}`}
        </Typography>
        {row?.map((item, xid) => (
          <>
            <div className={`nested-container ${field_label}-container`}>
              <Element
                schema={schema}
                parent={`${field_id}/fields/${index}`}
                ordorNo={xid}
                key={`${field_id}/fields/${item.field_id}/${xid}`}
                {...item}
              />
            </div>
          </>
        ))}

        <div className="nested-footer">
          <Button
            className="remove-button"
            key={String(`${field_id}/fields/${index}-button`)}
            onClick={() => handleRemoveCondition(field_id, index)}>
            Remove
          </Button>
          <SaveButton />
        </div>
      </Paper>
    )
  }

  return (
    <>
      <>
        {fields?.map((row, index) => (
          <>
            <div className="conditions-container">
              {field_label === 'conditions' ? (
                <>
                  {currentTab !== `${index}` &&
                  <div className="conditions-container__item-list">
                    <Typography className={'conditions-container__item-list--transactions'}>
                      <img className={'delete-icon'} src={deleteIcon} alt="" key={String(`${field_id}/fields/${index}-button`)} onClick={() => handleRemoveCondition(field_id, index)}/>
                      <img className={'edit-icon'} src={editIcon} alt="" key={index} id={index} onClick={(handleTabClick)}/>
                    </Typography>
                    <div className="conditions-container__item-list--title"> {`${field_label} ${index + 1}`}</div>
                    <div
                      className="conditions-container__item-list--description">{(conditionSummaryNewList ??  dispatch(setUpdateRuleOpen(false)))[0] ? conditionSummaryNewList[index] : ''}</div>
                  </div>
                  }
                  {currentTab === `${index}` &&
                    <ConditionsDetail row={row} index={index} />
                  }
                </>
              ):(
                <ConditionsDetail row={row} index={index} />
              )}
            </div>
          </>
        ))}
      </>
      <Button
        onClick={() => handleAddItem(field_id, schema)}
        className="new-subcontition-button"
      >
        Add New {field_label}
      </Button>
      <div className="mb-3 form-check">
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" checked={formRastUpdateChecboxStatus}  onChange={(event) => dispatch(setFormRastUpdateChecboxStatus(event.target.checked))}/>}
          label="Fast Update"
        />
      </div>
      </>

  );
};

export default Nested;
