/* eslint-disable import/no-cycle */
import { lazy } from 'react';

import Loader from './Loader/Loader';
import Logo from './Logo/Logo';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import BuggyCounter from './BuggyCounter/BuggyCounter';
import Modal from './Modal/Modal';
import Form from './Form/Form';
import AddForm from './Form/AddForm';
import SaveButton from './SaveButton';

const Header = lazy(() => import('./Header/Header'));
const Table = lazy(() => import('./Table/Table'));

export {
  Loader,
  ErrorBoundary,
  BuggyCounter,
  Header,
  Table,
  Logo,
  Modal,
  Form,
  AddForm,
  SaveButton
};
