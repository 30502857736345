/* eslint-disable no-async-promise-executor */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import API from '@API';
import { mapping } from '@Constants';
import { toast } from 'react-toastify';
import Cookie from 'js-cookie';
import history from '@History';
import { checkAxiosInstance } from '../network/apis/index';

const INITIAL_STATE = {
  categoryPageNumber: 0,
  categoryRuleName: '',
  formRastUpdateChecboxStatus: false,
};

export const getCategoriesRequest = createAsyncThunk('categories', async ({ data, params }, { getState }) => {
  const { isMarketplace } = getState().auth;
  const response = await API.getCategories(data, { ...params, ...{ mp: isMarketplace } });
  return response.data;
});

const slice = createSlice({
  name: 'category',
  initialState: INITIAL_STATE,
  reducers: {
    setCategryURL: (state, action) => {
      state.categoryPageNumber = 0;
      state.categoryUrl = action.payload;
    },
    setCategryID: (state, action) => {
      state.categoryPageNumber = 0;
      state.categoryId = action.payload;
    },
    setCategoryRuleName: (state, action) => {
      state.categoryPageNumber = 0;
      state.categoryRuleName = action.payload;
    },
    setCategoryPageNumber: (state, action) => {
      state.categoryPageNumber = action.payload;
    },
    setFormSubmitSaveButton: (state, action) => {
      state.formSubmitSaveButton = action.payload;
    },
    setFormRastUpdateChecboxStatus: (state, action) => {
      state.formRastUpdateChecboxStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getLoginRequest
    // builder.addCase(getLoginRequest.pending, (state, { meta }) => {
    //   state.requestId = meta?.requestId;
    // });
    // builder.addCase(getLoginRequest?.fulfilled, (state, { payload, meta }) => {
    //   const {
    //     success, data, error, api_key,
    //   } = payload;
    // });
    // builder.addCase(getLoginRequest?.rejected, (state, { payload, meta }) => {

    // });

    // getCategoriesRequest
    builder.addCase(getCategoriesRequest.pending, (state, { meta }) => {
      state.categorryRequestId = meta?.requestId;
      state.panddingCategorryRequestId = true;
    });
    builder.addCase(getCategoriesRequest?.fulfilled, (state, { payload, meta }) => {
      const { success, data, error } = payload;

      if (success) {
        state.categories = data.rules;
        if (data.count) {
          state.categoryCount = data.count;
        }
      }

      if (!success && error) {
        toast.dismiss();
        toast.error(error);
      }

      if (state.loginRequestId === meta?.requestId) {
        state.loginRequestId = null;
      }
      state.panddingCategorryRequestId = false;
    });
    builder.addCase(getCategoriesRequest.rejected, (state, { meta }) => {
      state.categorryRequestId = null;
      state.panddingCategorryRequestId = false;
    });
  },
});

export default slice.reducer;

export const {
  setCategryURL,
  setCategryID,
  setCategoryRuleName,
  setCategoryPageNumber,
  setFormSubmitSaveButton,
  setFormRastUpdateChecboxStatus,
} = slice.actions;
