/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-filename-extension */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import ThemeApp from './App';
import 'tailwindcss/tailwind.css';

ReactDOM.render(
  <Provider store={store}>
    <ThemeApp />
  </Provider>,
  document.querySelector('#root'),
);
