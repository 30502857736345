/* eslint-disable import/no-cycle */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import API from '@API';
import { Form } from '@Components';
import { resolveMapping } from '@Constants';
import { useDispatch } from 'react-redux';
import { addRuleRequest } from '../../store/auth';

const ruleInfo = (keyOptions) => {
  return [
    {
      field_id: 'name',
      field_label: 'name',
      field_type: 'text',
      field_value: '',
    },
    {
      field_id: 'text',
      field_label: 'text',
      field_type: 'text',
      field_value: '',
    },
    {
      field_id: 'conditions',
      field_label: 'conditions',
      field_type: 'nested',
      schema: [
        {
          field_id: 'key',
          field_label: 'key',
          field_type: 'select',
          field_options: keyOptions,
          field_value: 'web_third_price',
        },
        {
          field_id: 'condition',
          field_label: 'condition',
          field_type: 'select',
          field_options: [
            {
              key: 'eq',
              value: 'Eşittir',
            },
            {
              key: 'neq',
              value: 'Eşit Değildir',
            },
            {
              key: 'lt',
              value: 'Küçüktür',
            },
            {
              key: 'gt',
              value: 'Büyüktür',
            },
            {
              key: 'lte',
              value: 'Küçük Eşittir',
            },
            {
              key: 'gte',
              value: 'Büyük Eşittir',
            },
            {
              key: 'match',
              value: 'İçerir',
            },
          ],
          field_value: 'eq',
        },
        {
          field_id: 'value',
          field_label: 'value',
          field_type: 'text',
          field_value: '',
        },
        {
          field_id: 'score_value',
          field_label: 'score_value',
          field_type: 'text',
          field_value: '',
        },
        {
          field_id: 'direction',
          field_label: 'direction',
          field_type: 'select',
          field_options: [
            {
              key: 'Up',
              value: 'Up',
            },
            {
              key: 'Down',
              value: 'Down',
            },
          ],
          field_value: 'Up',
        },
        {
          field_id: 'multiplier',
          field_label: 'multiplier',
          field_type: 'text',
          field_value: '',
        },
        {
          field_id: 'subcondition',
          field_label: 'subcondition',
          field_type: 'nested',
          schema: [
            {
              field_id: 'operator',
              field_label: 'operator',
              field_type: 'select',
              field_options: [
                {
                  key: 'AND',
                  value: 'AND',
                },
                {
                  key: 'OR',
                  value: 'OR',
                },
              ],
              field_value: 'AND',
            },
            {
              field_id: 'key',
              field_label: 'key',
              field_type: 'select',
              field_options: keyOptions,
              field_value: 'web_third_price',
            },
            {
              field_id: 'condition',
              field_label: 'condition',
              field_type: 'select',
              field_options: [
                {
                  key: 'eq',
                  value: 'Eşittir',
                },
                {
                  key: 'neq',
                  value: 'Eşit Değildir',
                },
                {
                  key: 'lt',
                  value: 'Küçüktür',
                },
                {
                  key: 'gt',
                  value: 'Büyüktür',
                },
                {
                  key: 'lte',
                  value: 'Küçük Eşittir',
                },
                {
                  key: 'gte',
                  value: 'Büyük Eşittir',
                },
                {
                  key: 'match',
                  value: 'İçerir',
                },
              ],
              field_value: 'eq',
            },
            {
              field_id: 'value',
              field_label: 'value',
              field_type: 'text',
              field_value: '',
            },
          ],
          fields: [],
        },
      ],
      fields: [],
    },
  ];
};

const Add = () => {
  const dispatch = useDispatch();
  const [keyOptions, setKeyOptions] = useState();
  const onSubmit = (data) => {
    dispatch(addRuleRequest({
      data: {
        ...resolveMapping(data),
      },
    }));
  };

  useEffect(() => {
    (async () => {
      const res = await API.getRuleFields();
      setKeyOptions(res.data.data.map((item) => ({ key: item.name, value: item.title })));
    })();
  }, ['']);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        fields={ruleInfo(keyOptions)}
      />
    </>
  );
};

Add.displayName = 'Add Form';

export default Add;
