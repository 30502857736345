/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import { FormContext } from '../../FormContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 8,
    marginBottom: 4,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ElementSelect = ({
  field_id, field_label, field_placeholder, field_value, field_options, key,
}) => {
  const classes = useStyles();
  const { handleChange } = useContext(FormContext);

  return (
    <FormControl fullWidth variant="filled" className={classes.formControl}>
      <InputLabel htmlFor="filled-age-native-simple">{field_label}</InputLabel>
      <Select
        native
        key={key}
        fullWidth
        defaultValue={field_value}
        onChange={(event) => handleChange(field_id, event.target.value)}
        inputProps={{
          name: field_id,
          id: `filled-${field_id}-native-simple`,
        }}
      >
        {field_options?.map((field) => (
          <option key={field.key} value={field.key}>{field.value}</option>
        ))}
      </Select>
    </FormControl>
  );
};

export default ElementSelect;
