/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';

const Logo = () => {
  const {
    site_id,
    sitesLink,
  } = useSelector((state) => state.auth);

  const getLogo = () => sitesLink?.find((item) => site_id === item.site_id)?.site_logo;

  return (
    <img src={getLogo()} style={{ minWidth: 306 }} alt="logo" />
  );
};

Logo.displayName = 'Loader';

export default Logo;
