/* eslint-disable no-async-promise-executor */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import API from '@API';
import { mapping } from '@Constants';
import { toast } from 'react-toastify';
import Cookie from 'js-cookie';
import history from '@History';
import { checkAxiosInstance } from '../network/apis/index';

export const updateRuleRequest = createAsyncThunk('rule/update', ({ data, params }) => new Promise(async (res, rej) => {
  try {
    const response = await API.updateRule(data, params);
    res(response.data);
  } catch (error) {
    rej(error);
  }
}));

export const deleteRuleRequest = createAsyncThunk('rule/delete', async ({ data, params }) => {
  const response = await API.deleteRule(data, params);
  return response.data;
});

export const getRuleFieldsRequest = createAsyncThunk('rule/fields', async ({ data, params }) => {
  const response = await API.getRuleFields(data, params);
  return response.data;
});

export const getRuleInformationRequest = createAsyncThunk('rule/view', async ({ data, params }) => {
  const res = await API.getRuleFields();
  const response = await API.getRuleInformation(data, params);
  function compare(a, b) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  }
  return {
    ...response.data,
    keyOptions: res.data.data.sort(compare).map((item) => ({ key: item.name, value: item.title })),
  };
});

export const getRuleDetailRequest = createAsyncThunk('rule/view2', async ({ data, params }) => {
  const res = await API.getRuleFields();
  const response = await API.getRuleInformation(data, params);
  function compare(a, b) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  }
  return {
    ...response.data,
    keyOptions: res.data.data.sort(compare).map((item) => ({ key: item.name, value: item.title })),
  };
});

export const getLoginRequest = createAsyncThunk('user/login', async ({ password, email }) => {
  try {
    const response = await API.getLogin({ api_key: email, password });
    const { success, data } = response.data;
    if (success) {
      Cookie.set('token', data.access_token);
      Cookie.set('site_id', data.site_id);
      Cookie.set('api_key', data.api_key);
      checkAxiosInstance();
      history.push('/');
    }
    return { ...response.data, ...{ api_key: email } };
  } catch (error) {
    toast.dismiss();
    toast.error(JSON.stringify(error));
    return {
      success: false,
      message: JSON.stringify(error),
    };
  }
});

export const getProductsRequest = createAsyncThunk('products', async ({ data, params }, { getState }) => {
  const { isMarketplace } = getState().auth;
  const response = await API.getProducts(data, { ...params, ...{ mp: isMarketplace } });
  return response.data;
});

export const getRulequeueRequest = createAsyncThunk('/api/rule/queue', async ({ data, params }, { getState }) => {
  const response = await API.getRulequeue(data);
  return response.data;
});

export const getPreviewProductsRequest = createAsyncThunk('products/draft', async ({ data, params }, { getState }) => {
  const response = await API.getProducts(data, { ...params });
  return { ...response.data, params };
});

export const updateProductOrderFirstRequest = createAsyncThunk('products/first', async ({ data, params }, { getState, dispatch }) => {
  const { rowsPerPage, productPage, categoryId } = getState().auth;
  const response = await API.updateProductOrderFirst(data, params);
  if (response?.data?.success) {
    dispatch(getProductsRequest({
      params: {
        id: categoryId,
        limit: rowsPerPage,
        page: parseFloat(productPage === 0 ? 1 : productPage),
        mp: 0,
        name: data.name,
        sku: data.sku,
      },
    }));
  }
  return response.data;
});

export const updateProductOrderLastRequest = createAsyncThunk('products/last', async ({ data, params }, { getState, dispatch }) => {
  const { rowsPerPage, productPage, categoryId } = getState().auth;
  const response = await API.updateProductOrderLast(data, params);
  if (response?.data?.success) {
    dispatch(getProductsRequest({
      params: {
        id: categoryId,
        limit: rowsPerPage,
        page: parseFloat(productPage === 0 ? 1 : productPage),
        mp: 0,
        name: data.name,
        sku: data.sku,
      },
    }));
  }
  return response.data;
});

export const updateProductOrderWithIndexRequest = createAsyncThunk('products/index', async ({ data, params }, { getState, dispatch }) => {
  const { rowsPerPage, productPage, categoryId } = getState().auth;
  const response = await API.updateProductOrderWithIndex(data, params);
  if (response?.data?.success) {
    dispatch(getProductsRequest({
      params: {
        id: categoryId,
        limit: rowsPerPage,
        page: parseFloat(productPage === 0 ? 1 : productPage),
        mp: 0,
        name: data.name,
        sku: data.sku,
      },
    }));
  }
  return response.data;
});

export const unsetCustomOrderRequest = createAsyncThunk('category/unset', async ({ data, params }, { getState, dispatch }) => {
  const { rowsPerPage, productPage, categoryId } = getState().auth;
  const response = await API.unsetCustomOrder(data, params);
  if (response?.data?.success) {
    dispatch(getProductsRequest({
      params: {
        id: categoryId,
        limit: rowsPerPage,
        page: parseFloat(productPage === 0 ? 1 : productPage),
        mp: 0,
        name: data.name,
        sku: data.sku,
      },
    }));
  }
  return response.data;
});

export const unsetAllCustomOrderRequest = createAsyncThunk('category/unsetall', async ({ data, params }, { getState, dispatch }) => {
  const { rowsPerPage, productPage, categoryId } = getState().auth;
  const response = await API.unsetAllCustomOrder(data, params);
  if (response?.data?.success) {
    dispatch(getProductsRequest({
      params: {
        id: categoryId,
        limit: rowsPerPage,
        page: parseFloat(productPage === 0 ? 1 : productPage),
        mp: 0,
      },
    }));
  }
  return response.data;
});

export const updateCategoryRuleRequest = createAsyncThunk('category/rule/update', async ({ data, params }, { getState, dispatch }) => {
  const { rowsPerPage, productPage, categoryId } = getState().auth;
  const response = await API.updateCategoryRule(data, params);
  if (response?.data?.success) {
    dispatch(getProductsRequest({
      params: {
        id: categoryId,
        limit: rowsPerPage,
        page: parseFloat(productPage === 0 ? 1 : productPage),
        mp: 0,
      },
    }));
  }
  return response.data;
});

export const getCategoryExportRequest = createAsyncThunk('category/export', async ({ data, params }) => {
  const response = await API.getCategoryExport(data, params);
  if (response.data.success === true) window.location.href = response.data.redirect;
  return response.data;
});

export const getCategoryImportRequest = createAsyncThunk('category/import', async ({ data, params }, { getState, dispatch }) => {
  const { rowsPerPage, productPage, categoryId } = getState().auth;
  const response = await API.getCategoryImport(data, params);
  if (response?.data?.success) {
    toast.dismiss();
    toast.success('İçe Aktar Başarılı bir şekilde tamamlanmıştır.');
    dispatch(getProductsRequest({
      params: {
        id: categoryId,
        limit: rowsPerPage,
        page: parseFloat(productPage === 0 ? 1 : productPage),
        mp: 0,
      },
    }));
  }
  return response.data;
});

export const getRulesRequest = createAsyncThunk('rules', async ({ data, params }) => {
  const response = await API.getRules(data, params);
  return response.data;
});

export const getSitesRequest = createAsyncThunk('/api/sites/list', async ({ data, params }) => {
  const response = await API.getSites(data, params);
  return response.data;
});

export const addRuleRequest = createAsyncThunk('rule/add', async ({ data, params }, { getState, dispatch }) => {
  const { rowsPerPage, productPage } = getState().auth;
  const response = await API.addRule(data, params);
  const { success, message } = response.data;
  if (success) {
    toast.success('İşleminizi başarıyla kaydedilmiştir. Kayıt Listesi ekranından takip edebilirsiniz.');
  }
  if (!success && message) {
    toast.dismiss();
    toast.error(message);
  }
  if (response?.data?.success) {
    dispatch(getRulesRequest({
      params: {
        limit: rowsPerPage,
        page: parseFloat(productPage === 0 ? 1 : productPage),
      },
    }));
  }
  return response.data;
});

export const getRuleViewRequest = createAsyncThunk('rule/view', async ({ data, params }) => {
  const response = await API.getRuleView(data, params);
  return response.data;
});

export const getSiteIdRequest = createAsyncThunk('api/auth/switch', async (arg, { dispatch }) => {
  const response = await API.getSiteId(arg.data, arg.params);
  const { success, data } = response.data;
  if (success) {
    Cookie.set('token', data.access_token);
    Cookie.set('site_id', data.site_id);
    Cookie.set('api_key', data.api_key);
    checkAxiosInstance();
    window.location.href = '/';
  }
  return response.data;
});

const INITIAL_STATE = {
  token: Cookie.get('token'),
  site_id: Cookie.get('site_id'),
  productName: '',
  productSku: '',
  productPage: 1,
  rowsPerPage: 75,
  isMarketplace: 0,
  selectedRule: '',
  rulesAdd: '',
  sitesLink: [],
  operators: [
    { key: 'AND', value: 'AND' },
    { key: 'OR', value: 'OR' },
  ],
  conditions: [
    { key: 'eq', value: 'Eşittir' },
    { key: 'neq', value: 'Eşit Değildir' },
    { key: 'lt', value: 'Küçüktür' },
    { key: 'gt', value: 'Büyüktür' },
    { key: 'lte', value: 'Küçük Eşittir' },
    { key: 'gte', value: 'Büyük Eşittir' },
    { key: 'match', value: 'İçerir' },
  ],
};

const slice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setProductName: (state, action) => {
      state.productPage = 0;
      state.productName = action.payload;
    },
    setDraftNameData: (state, action) => {
      state.nameSaveAsDraft = action.payload;
    },
    setProductSku: (state, action) => {
      state.productPage = 0;
      state.productSku = action.payload;
    },
    setUpdateRuleOpen: (state, action) => {
      state.updateRuleOpen = action.payload;
    },
    setNewRuleOpen: (state, action) => {
      state.newRuleOpen = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setMarketplace: (state, action) => {
      state.isMarketplace = action.payload;
    },
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    setProductPage: (state, action) => {
      state.productPage = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedRule: (state, action) => {
      // localStorage.setItem('token', action.payload);
      state.selectedRule = action.payload;
    },
    setAuthToken: (state, action) => {
      // localStorage.setItem('token', action.payload);
      Cookie.set('token', action.payload);
      state.token = action.payload;
    },
    deleteAuthToken: (state, action) => {
      Cookie.remove('token');
      state.token = null;
    },
    setDisabledButton: (state, action) => {
      state.disabledButton = action.payload;
    },
    setSaveAsButtonStatus: (state, action) => {
      state.saveAsButtonStatus = action.payload;
    },
    setDraftModeDisabled: (state, action) => {
      state.draftMode = action.payload;
    },
    setDraftConditionsData: (state, action) => {
      state.draftConditionsData = action.payload;
    },
    setConditionSummaryList: (state, action) => {
      state.conditionSummaryList = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getLoginRequest
    builder.addCase(getLoginRequest.pending, (state, { meta }) => {
      state.loginRequestId = meta?.requestId;
    });
    builder.addCase(getLoginRequest?.fulfilled, (state, { payload, meta }) => {
      const {
        success, data, error, api_key,
      } = payload;

      if (success) {
        state.token = data.access_token;
        state.expires_at = data.expires_at;
        state.site_id = data.site_id;
        state.api_key = api_key;
        Cookie.set('token', data.access_token);
        Cookie.set('site_id', data.site_id);
        Cookie.set('api_key', api_key);
        localStorage.setItem('userName', data.user);
      }

      if (!success && error) {
        toast.dismiss();
        toast.error(error);
      }

      if (state.loginRequestId === meta?.requestId) {
        state.loginRequestId = null;
      }
    });
    builder.addCase(getLoginRequest?.rejected, (state, { payload, meta }) => {
      if (state.loginRequestId === meta?.requestId) {
        state.loginRequestId = null;
        toast.dismiss();
        toast.error(JSON.stringify(payload));
      }
    });

    // getsiteId
    builder.addCase(getSiteIdRequest.pending, (state, { meta }) => {
      state.getSiteIdRequestId = meta?.requestId;
      state.getSiteIdPending = true;
    });
    builder.addCase(getSiteIdRequest?.fulfilled, (state, { payload, meta }) => {
      const { success, data, message } = payload;

      if (success) {
        state.token = data.access_token;
        state.expires_at = data.expires_at;
        state.site_id = data.site_id;
        Cookie.set('token', data.access_token);
        Cookie.set('site_id', data.site_id);
      }

      if (!success) {
        toast.dismiss();
        toast.error(message);
      }

      if (state.getSiteIdRequestId === meta?.requestId) {
        state.getSiteIdPending = false;
        state.getSiteIdRequestId = null;
      }
    });

    // getRulesRequest
    builder.addCase(getRulesRequest.pending, (state, { meta }) => {
      state.ruleRequestId = meta?.requestId;
    });
    builder.addCase(getRulesRequest?.fulfilled, (state, { payload, meta }) => {
      const { success, data, error } = payload;

      if (success) {
        state.rules = data.rules;
        state.selectedRule = data.rules[data.rules.length - 1]?.id;
      }

      if (!success && error) {
        toast.dismiss();
        toast.error(error);
      }

      if (state.ruleRequestId === meta?.requestId) {
        state.ruleRequestId = null;
      }
    });

    // getRulesRequest
    builder.addCase(getProductsRequest.pending, (state, { meta }) => {
      state.getProductsRequestId = meta?.requestId;
      state.panddingProducts = true;
    });
    builder.addCase(getProductsRequest?.fulfilled, (state, { payload, meta }) => {
      const { success, data, error } = payload;

      if (success) {
        state.products = Object.values(data.products);
        if (!state.selectedCategory) {
          state.selectedCategory = data.category;
        }
        if (data.product_count) {
          state.productCount = data.product_count;
        }
      }

      if (!success && error) {
        toast.dismiss();
        toast.error(error);
      }

      if (state.getProductsRequestId === meta?.requestId) {
        state.getProductsRequestId = null;
      }
      state.panddingProducts = false;
    });
    builder.addCase(getProductsRequest.rejected, (state, { meta }) => {
      state.getProductsRequestId = null;
      state.panddingProducts = true;
    });

    // getRuleInformationRequest
    builder.addCase(getRuleInformationRequest.pending, (state, { meta }) => {
      state.ruleInfoRequestId = meta?.requestId;
      state.panddingRuleInfo = true;
    });
    builder.addCase(getRuleInformationRequest?.fulfilled, (state, { payload, meta }) => {
      const {
        success, data, error, keyOptions,
      } = payload;

      if (success) {
        const ruleInfo = { ...data };
        ruleInfo.conditions = JSON.parse(ruleInfo?.conditions || '[]');
        state.backup = { ...data };
        state.ruleInfo = mapping(ruleInfo, keyOptions);
        state.conditionSummaryList = ruleInfo?.conditionSummary;

      }

      if (!success && error) {
        toast.dismiss();
        toast.error(error);
      }

      if (state.ruleInfoRequestId === meta?.requestId) {
        state.ruleInfoRequestId = null;
      }
      state.panddingRuleInfo = false;
    });

    // getRuleDetailRequest
    builder.addCase(getRuleDetailRequest.pending, (state, { meta }) => {
      state.ruleDetailRequestId = meta?.requestId;
      state.ruleDetailPending = true;
      state.selectedRuleInfo = null;
    });
    builder.addCase(getRuleDetailRequest?.fulfilled, (state, { payload, meta }) => {
      const {
        success, data, error, keyOptions,
      } = payload;

      if (success) {
        const ruleInfo = { ...data };
        ruleInfo.conditions = JSON.parse(ruleInfo?.conditions || '[]');
        state.selectedRuleInfo = mapping(ruleInfo, keyOptions);
      }

      if (!success && error) {
        toast.dismiss();
        toast.error(error);
      }

      if (state.ruleDetailRequestId === meta?.requestId) {
        state.ruleDetailRequestId = null;
        state.ruleDetailPending = false;
      }
    });
    builder.addCase(getRuleDetailRequest.rejected, (state, { meta }) => {
      state.ruleDetailRequestId = null;
      state.selectedRuleInfo = null;
      state.ruleDetailPending = false;
    });

    // deleteRuleRequest
    builder.addCase(deleteRuleRequest.pending, (state, { meta }) => {
      state.deleteRuleRequestId = meta?.requestId;
      state.loadingDeleteRule = true;
    });
    builder.addCase(deleteRuleRequest?.fulfilled, (state, { payload, meta }) => {
      const { success, data, message } = payload;
      if (success) {
        window.location.href = '/';
      }

      if (!success && message) {
        toast.dismiss();
        toast.error(message);
      }

      if (state.deleteRuleRequestId === meta?.requestId) {
        state.deleteRuleRequestId = null;
      }
      state.loadingDeleteRule = false;
    });

    // Save List
    builder.addCase(getRulequeueRequest.pending, (state, { payload, meta }) => {
      state.queueRequestId = meta?.requestId;
      state.loadingRulequeue = true;
    });

    builder.addCase(getRulequeueRequest?.fulfilled, (state, { payload, meta }) => {
      const { success, data } = payload;
      if (success) {
        state.queue = Object.values(data);
      }
      state.loadingRulequeue = false;
    });

    builder.addCase(getRulequeueRequest.rejected, (state, { meta }) => {
      state.queueRequestId = null;
      state.loadingRulequeue = false;
    });

    // Sites List
    builder.addCase(getSitesRequest.pending, (state, { payload, meta }) => {
      state.queueRequestId = meta?.requestId;
      state.loadingRulequeue = true;
    });

    builder.addCase(getSitesRequest?.fulfilled, (state, { payload, meta }) => {
      const { success, data } = payload;
      if (success) {
        state.sitesLink = data.sites;
      }
      state.loadingRulequeue = false;
    });

    builder.addCase(getSitesRequest.rejected, (state, { meta }) => {
      state.queueRequestId = null;
      state.loadingRulequeue = false;
    });

    // addRuleRequest
    builder.addCase(addRuleRequest.pending, (state, { payload, meta }) => {
      // state.queueRequestId = meta?.requestId;
      state.loadingRule = true;
    });

    builder.addCase(addRuleRequest?.fulfilled, (state, { payload, meta }) => {
      state.loadingRule = false;
    });

    builder.addCase(addRuleRequest.rejected, (state, { meta }) => {
      state.loadingRule = false;
    });

    // updateRuleRequest
    builder.addCase(updateRuleRequest.pending, (state, { meta }) => {
      // state.queueRequestId = meta?.requestId;
      state.loadingUpdateRule = true;
    });

    builder.addCase(updateRuleRequest?.fulfilled, (state, { payload }) => {
      const { success, message } = payload;
      if (success) {
        toast.success('İşleminizi başarıyla kaydedilmiştir. Kayıt Listesi ekranından takip edebilirsiniz.');
      } else {
        toast.error(message);
      }
      state.loadingUpdateRule = false;
    });

    builder.addCase(updateRuleRequest.rejected, (state, { meta }) => {
      state.loadingUpdateRule = false;
    });

    // getPreviewProductsRequest
    builder.addCase(getPreviewProductsRequest.pending, (state, { payload, meta }) => {
      state.queueRequestId = meta?.requestId;
      state.panddingPreviewProducts = true;
    });

    builder.addCase(getPreviewProductsRequest?.fulfilled, (state, { payload, meta }) => {
      const {
        success,
        data,
        error,
        params,
      } = payload;

      if (success) {
        localStorage.setItem('params', JSON.stringify(params));
        if (!state.draftMode) {
          state.productPage = 0;
        }
        state.draftMode = true;
        state.newRuleOpen = false;
        state.updateRuleOpen = false;
        state.products = Object.values(data.products);

        if (!state.selectedCategory) {
          state.selectedCategory = data.category;
        }
        if (data.product_count) {
          state.productCount = data.product_count;
        }
      }

      if (!success && error) {
        toast.dismiss();
        toast.error(error);
      }

      if (state.getProductsRequestId === meta?.requestId) {
        state.getProductsRequestId = null;
      }
      state.panddingPreviewProducts = false;
    });

    builder.addCase(getPreviewProductsRequest.rejected, (state, { meta }) => {
      state.queueRequestId = null;
      state.panddingPreviewProducts = false;
    });

    // updateCategoryRuleRequest
    builder.addCase(updateCategoryRuleRequest.pending, (state, { meta }) => {
      state.updateCategoryRuleRequestId = meta?.requestId;
      state.selectedCategory = null;
    });

    // Export
    builder.addCase(getCategoryExportRequest.pending, (state, { meta }) => {
      state.loadingUpdateRule = true;
    });

    builder.addCase(getCategoryExportRequest?.fulfilled, (state, { payload }) => {
      const { success, message } = payload;
      state.loadingUpdateRule = false;
    });

    builder.addCase(getCategoryExportRequest.rejected, (state, { meta }) => {
      state.loadingUpdateRule = false;
    });

    // Import
    builder.addCase(getCategoryImportRequest.pending, (state, { meta }) => {
      state.loadingUpdateRule = true;
    });

    builder.addCase(getCategoryImportRequest?.fulfilled, (state, { payload }) => {
      const { success, message } = payload;
      state.loadingUpdateRule = false;
    });

    builder.addCase(getCategoryImportRequest.rejected, (state, { meta }) => {
      state.loadingUpdateRule = false;
    });
  },
});

export default slice.reducer;

export const {
  setAuthToken,
  deleteAuthToken,
  setSelectedRule,
  setProductPage,
  setRowsPerPage,
  setCategoryId,
  setDisabledButton,
  setSaveAsButtonStatus,
  setMarketplace,
  setSelectedCategory,
  setUpdateRuleOpen,
  setNewRuleOpen,
  setDraftModeDisabled,
  setDraftConditionsData,
  setProductName,
  setProductSku,
  setDraftNameData,
  setConditionSummaryList,
} = slice.actions;
