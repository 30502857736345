/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { debounce } from '@Constants';
import { FormContext } from '../../FormContext';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../../../store/loading';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 8,
    marginBottom: 4,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ElementInput = ({
  field_id, field_label, field_placeholder, field_value, key,
}) => {

  const dispatch = useDispatch();

  const classes = useStyles();
  const { handleChange } = useContext(FormContext);
  const handleOnChange = debounce(handleChange, 1000);

  useEffect(() => {
    return () => {
      dispatch(setLoader(false))
    }
  }, [])


  return (
    <FormControl key={key} fullWidth variant="filled" data-test={field_label}>
      <TextField
        fullWidth
        key={key}
        label={field_label}
        id="filled-margin-dense"
        defaultValue={field_value}
        onFocus={() => dispatch(setLoader(true))}
        onBlur={() => dispatch(setLoader(false))}
        onChange={(event) => handleOnChange(field_id, event.target.value)}
        margin="dense"
        variant="filled"
      />
    </FormControl>
  );
};

export default ElementInput;

// className={`${classes.formControl} condition-summary-container ${(field_id === 'name' || field_id === 'conditionSummary') ? 'none' : ''}`}
