import { ReactComponent as IconLoading } from '../assets/loading.svg';
import { ReactComponent as IconFLOLogo } from '../assets/flo.svg';
import { ReactComponent as IconLumberjack } from '../assets/lumberjackit.svg';
import { ReactComponent as IconLumberjackTr } from '../assets/lumberjacktr.svg';
import { ReactComponent as IconNinewest } from '../assets/ninewest.svg';
import { ReactComponent as IconInstreet } from '../assets/instreet.svg';
import { ReactComponent as IconInci } from '../assets/inci.svg';

export {
  IconLoading,
  IconFLOLogo,
  IconLumberjack,
  IconLumberjackTr,
  IconNinewest,
  IconInstreet,
  IconInci,
};
