/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Form } from '@Components';
import { resolveMapping } from '@Constants';
import { IconFLOLogo } from '@Icons';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { addRuleRequest } from '../../../store/auth';

const ruleInfo = [
  {
    field_id: 'name',
    field_label: 'name',
    field_type: 'text',
    field_value: '',
  },
  {
    field_id: 'text',
    field_label: 'text',
    field_type: 'text',
    field_value: '',
  },
  {
    field_id: 'conditions',
    field_label: 'conditions',
    field_type: 'nested',
    schema: [
      {
        field_id: 'key',
        field_label: 'key',
        field_type: 'select',
        field_options: [
          {
            key: 'web_third_price',
            value: 'Web third price',
          },
          {
            key: 'views',
            value: '7 Günlük Görüntülenme Sayısı',
          },
          {
            key: 'urungrubu',
            value: 'Urun Grubu',
          },
          {
            key: 'unique_purchases',
            value: '7 Günlük Satın Alma Sayısı',
          },
          {
            key: 'supplier',
            value: 'Supplier',
          },
          {
            key: 'stokgrubu',
            value: 'stok grubu',
          },
          {
            key: 'stock',
            value: 'Stok',
          },
          {
            key: 'sizepercent',
            value: 'Kırıklık',
          },
          {
            key: 'sezonyil',
            value: 'Sezon Yıl',
          },
          {
            key: 'sezon',
            value: 'Sezon',
          },
          {
            key: 'saleprice',
            value: 'Satış Fiyatı',
          },
          {
            key: 'renk',
            value: 'Renk',
          },
          {
            key: 'mobile_third_price',
            value: 'Mobile third price',
          },
          {
            key: 'merchantid',
            value: 'Satıcı',
          },
          {
            key: 'marj',
            value: 'Marj',
          },
          {
            key: 'last7salescount',
            value: 'Son 7 Günlük Satış',
          },
          {
            key: 'last28salescount',
            value: 'Son 28 Günlük Satış',
          },
          {
            key: 'last21salescount',
            value: 'Son 21 Günlük Satış',
          },
          {
            key: 'last14salescount',
            value: 'Son 14 Günlük Satış',
          },
          {
            key: 'gender',
            value: 'Cinsiyet',
          },
          {
            key: 'editor_in_days',
            value: 'Editör Onay üzerinden geçen süre',
          },
          {
            key: 'ead',
            value: 'Editör Onay Tarihi',
          },
          {
            key: 'discount',
            value: 'İndirim',
          },
          {
            key: 'daily_view',
            value: 'Günlük Görüntülenme Sayısı',
          },
          {
            key: 'daily_uniquepurchases',
            value: 'Günlük Satın Alma Sayısı',
          },
          {
            key: 'daily_conversionrate',
            value: 'Günlük Conversion Rate',
          },
          {
            key: 'daily_added_to_card',
            value: 'Günlük Sepete Eklenme Sayısı',
          },
          {
            key: 'cost',
            value: 'Maliyet',
          },
          {
            key: 'conversion_rate',
            value: '7 Günlük Conversion Rate',
          },
          {
            key: 'brand',
            value: 'Brand',
          },
          {
            key: 'bedensayisi',
            value: 'Beden Sayısı',
          },
          {
            key: 'bedengrubu',
            value: 'Beden Grubu',
          },
          {
            key: 'added_to_card',
            value: '7 Günlük Sepete Eklenme Sayısı',
          },
        ],
        field_value: 'web_third_price',
      },
      {
        field_id: 'condition',
        field_label: 'condition',
        field_type: 'select',
        field_options: [
          {
            key: 'eq',
            value: 'Eşittir',
          },
          {
            key: 'neq',
            value: 'Eşit Değildir',
          },
          {
            key: 'lt',
            value: 'Küçüktür',
          },
          {
            key: 'gt',
            value: 'Büyüktür',
          },
          {
            key: 'lte',
            value: 'Küçük Eşittir',
          },
          {
            key: 'gte',
            value: 'Büyük Eşittir',
          },
          {
            key: 'match',
            value: 'İçerir',
          },
        ],
        field_value: 'eq',
      },
      {
        field_id: 'value',
        field_label: 'value',
        field_type: 'text',
        field_value: '',
      },
      {
        field_id: 'score_value',
        field_label: 'score_value',
        field_type: 'text',
        field_value: '',
      },
      {
        field_id: 'direction',
        field_label: 'direction',
        field_type: 'select',
        field_options: [
          {
            key: 'Up',
            value: 'Up',
          },
          {
            key: 'Down',
            value: 'Down',
          },
        ],
        field_value: 'Up',
      },
      {
        field_id: 'multiplier',
        field_label: 'multiplier',
        field_type: 'text',
        field_value: '',
      },
      {
        field_id: 'subcondition',
        field_label: 'subcondition',
        field_type: 'nested',
        schema: [
          {
            field_id: 'operator',
            field_label: 'operator',
            field_type: 'select',
            field_options: [
              {
                key: 'AND',
                value: 'AND',
              },
              {
                key: 'OR',
                value: 'OR',
              },
            ],
            field_value: 'AND',
          },
          {
            field_id: 'key',
            field_label: 'key',
            field_type: 'select',
            field_options: [
              {
                key: 'web_third_price',
                value: 'Web third price',
              },
              {
                key: 'views',
                value: '7 Günlük Görüntülenme Sayısı',
              },
              {
                key: 'urungrubu',
                value: 'Urun Grubu',
              },
              {
                key: 'unique_purchases',
                value: '7 Günlük Satın Alma Sayısı',
              },
              {
                key: 'supplier',
                value: 'Supplier',
              },
              {
                key: 'stokgrubu',
                value: 'stok grubu',
              },
              {
                key: 'stock',
                value: 'Stok',
              },
              {
                key: 'sizepercent',
                value: 'Kırıklık',
              },
              {
                key: 'sezonyil',
                value: 'Sezon Yıl',
              },
              {
                key: 'sezon',
                value: 'Sezon',
              },
              {
                key: 'saleprice',
                value: 'Satış Fiyatı',
              },
              {
                key: 'renk',
                value: 'Renk',
              },
              {
                key: 'mobile_third_price',
                value: 'Mobile third price',
              },
              {
                key: 'merchantid',
                value: 'Satıcı',
              },
              {
                key: 'marj',
                value: 'Marj',
              },
              {
                key: 'last7salescount',
                value: 'Son 7 Günlük Satış',
              },
              {
                key: 'last28salescount',
                value: 'Son 28 Günlük Satış',
              },
              {
                key: 'last21salescount',
                value: 'Son 21 Günlük Satış',
              },
              {
                key: 'last14salescount',
                value: 'Son 14 Günlük Satış',
              },
              {
                key: 'gender',
                value: 'Cinsiyet',
              },
              {
                key: 'editor_in_days',
                value: 'Editör Onay üzerinden geçen süre',
              },
              {
                key: 'ead',
                value: 'Editör Onay Tarihi',
              },
              {
                key: 'discount',
                value: 'İndirim',
              },
              {
                key: 'daily_view',
                value: 'Günlük Görüntülenme Sayısı',
              },
              {
                key: 'daily_uniquepurchases',
                value: 'Günlük Satın Alma Sayısı',
              },
              {
                key: 'daily_conversionrate',
                value: 'Günlük Conversion Rate',
              },
              {
                key: 'daily_added_to_card',
                value: 'Günlük Sepete Eklenme Sayısı',
              },
              {
                key: 'cost',
                value: 'Maliyet',
              },
              {
                key: 'conversion_rate',
                value: '7 Günlük Conversion Rate',
              },
              {
                key: 'brand',
                value: 'Brand',
              },
              {
                key: 'bedensayisi',
                value: 'Beden Sayısı',
              },
              {
                key: 'bedengrubu',
                value: 'Beden Grubu',
              },
              {
                key: 'added_to_card',
                value: '7 Günlük Sepete Eklenme Sayısı',
              },
            ],
            field_value: 'web_third_price',
          },
          {
            field_id: 'condition',
            field_label: 'condition',
            field_type: 'select',
            field_options: [
              {
                key: 'eq',
                value: 'Eşittir',
              },
              {
                key: 'neq',
                value: 'Eşit Değildir',
              },
              {
                key: 'lt',
                value: 'Küçüktür',
              },
              {
                key: 'gt',
                value: 'Büyüktür',
              },
              {
                key: 'lte',
                value: 'Küçük Eşittir',
              },
              {
                key: 'gte',
                value: 'Büyük Eşittir',
              },
              {
                key: 'match',
                value: 'İçerir',
              },
            ],
            field_value: 'eq',
          },
          {
            field_id: 'value',
            field_label: 'value',
            field_type: 'text',
            field_value: '',
          },
        ],
        fields: [],
      },
    ],
    fields: [],
  },
];

const Add = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(addRuleRequest({
      data: {
        ...resolveMapping(data),
      },
    }));
  };

  return (
    <>
      <div className="" style={{ display: 'flex', alignItems: 'center' }}>
        <button
          type="button"
          onClick={() => history.push('')}
          style={{
            padding: 0, backgroundColor: 'transparent', border: '0', cursor: 'pointer',
          }}
        >
          <IconFLOLogo style={{ width: 306 }} />
        </button>
        <Typography variant="h3" component="h1" style={{ marginLeft: 'auto' }}>
          Add Rule
        </Typography>
      </div>
      <Form
        onSubmit={onSubmit}
        fields={ruleInfo}
      />
    </>
  );
};

export default Add;
