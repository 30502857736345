/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle,max-len */
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const isMobile = (() => window.innerWidth < 991)();

const MySwal = withReactContent(Swal);

export const Alert = ({ message }) => {
  MySwal.fire({
    title: <p>{message}</p>,
    // footer: 'Copyright 2018',
    didOpen: () => {
      // MySwal.clickConfirm()
    },
  });
};

export const priceFormat = (value) => {
  if (!value) {
    return 0;
  }

  return parseFloat(value)
    .toFixed(2)
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
};

const schemaSubcondition = (keyOptions) => (
  [
    {
      field_id: 'operator',
      field_label: 'operator',
      field_type: 'select',
      field_options: [
        {
          key: 'AND',
          value: 'AND',
        },
        {
          key: 'OR',
          value: 'OR',
        },
      ],
      field_value: 'AND',
    },
    {
      field_id: 'key',
      field_label: 'key',
      field_type: 'select',
      field_options: keyOptions,
      field_value: 'web_third_price',
    },
    {
      field_id: 'condition',
      field_label: 'condition',
      field_type: 'select',
      field_options: [
        {
          key: 'eq',
          value: 'Eşittir',
        },
        {
          key: 'neq',
          value: 'Eşit Değildir',
        },
        {
          key: 'lt',
          value: 'Küçüktür',
        },
        {
          key: 'gt',
          value: 'Büyüktür',
        },
        {
          key: 'lte',
          value: 'Küçük Eşittir',
        },
        {
          key: 'gte',
          value: 'Büyük Eşittir',
        },
        {
          key: 'match',
          value: 'İçerir',
        },
      ],
      field_value: 'eq',
    },
    {
      field_id: 'value',
      field_label: 'value',
      field_type: 'text',
      field_value: '',
    },
  ]
);

const schemaCondition = (keyOptions) => (
  [
    {
      field_id: 'key',
      field_label: 'key',
      field_type: 'select',
      field_options: keyOptions,
      field_value: 'web_third_price',
    },
    {
      field_id: 'condition',
      field_label: 'condition',
      field_type: 'select',
      field_options: [
        {
          key: 'eq',
          value: 'Eşittir',
        },
        {
          key: 'neq',
          value: 'Eşit Değildir',
        },
        {
          key: 'lt',
          value: 'Küçüktür',
        },
        {
          key: 'gt',
          value: 'Büyüktür',
        },
        {
          key: 'lte',
          value: 'Küçük Eşittir',
        },
        {
          key: 'gte',
          value: 'Büyük Eşittir',
        },
        {
          key: 'match',
          value: 'İçerir',
        },
      ],
      field_value: 'eq',
    },
    {
      field_id: 'value',
      field_label: 'value',
      field_type: 'text',
      field_value: '',
    },
    {
      field_id: 'score_value',
      field_label: 'score_value',
      field_type: 'text',
      field_value: '',
    },
    {
      field_id: 'direction',
      field_label: 'direction',
      field_type: 'select',
      field_options: [
        {
          key: 'Up',
          value: 'Up',
        },
        {
          key: 'Down',
          value: 'Down',
        },
      ],
      field_value: 'Up',
    },
    {
      field_id: 'multiplier',
      field_label: 'multiplier',
      field_type: 'text',
      field_value: '',
    },
    {
      field_id: 'subcondition',
      field_label: 'subcondition',
      field_type: 'nested',
      schema: JSON.parse(JSON.stringify(schemaSubcondition(keyOptions))),
      fields: [
      ],
    },
  ]
);

export const mapping = (data, keyOptions) => {
  let fields = [];
  let order = 0;
  for (const item in data) {
    if (item === 'conditions') {
      const subFields = data[item].map((a) => mapping(a, keyOptions));
      fields.push({
        order: 1000 + (order++),
        field_id: item,
        field_label: item,
        field_type: 'nested',
        schema: schemaCondition(keyOptions),
        fields: subFields,
      });
    } else if (item === 'subcondition') {
      const subFields = data[item].map((a) => mapping(a, keyOptions));
      fields.push({
        order: 1000 + (order++),
        field_id: item,
        field_label: item,
        field_type: 'nested',
        schema: schemaSubcondition(keyOptions),
        fields: subFields,
      });
    } else if (item === 'condition') {
      fields.push({
        order: 1000 + (order++),
        field_id: item,
        field_label: item,
        field_type: 'select',
        field_options: [
          { key: 'eq', value: 'Eşittir' },
          { key: 'neq', value: 'Eşit Değildir' },
          { key: 'lt', value: 'Küçüktür' },
          { key: 'gt', value: 'Büyüktür' },
          { key: 'lte', value: 'Küçük Eşittir' },
          { key: 'gte', value: 'Büyük Eşittir' },
          { key: 'match', value: 'İçerir' },
        ],
        field_value: data[item],
      });
    } else if (item === 'operator') {
      fields.push({
        order: 1000 + (order++),
        field_id: item,
        field_label: item,
        field_type: 'select',
        field_options: [
          { key: 'AND', value: 'AND' },
          { key: 'OR', value: 'OR' },
        ],
        field_value: data[item],
      });
    } else if (item === 'direction') {
      fields.push({
        order: 1000 + (order++),
        field_id: item,
        field_label: item,
        field_type: 'select',
        field_options: [
          { key: 'Up', value: 'Up' },
          { key: 'Down', value: 'Down' },
        ],
        field_value: data[item],
      });
    } else if (item === 'key') {
      fields.push({
        order: 1000 + (order++),
        field_id: item,
        field_label: item,
        field_type: 'select',
        field_options: keyOptions,
        field_value: data[item],
      });
    } else if (['id', 'deleted_at'].includes(item)) {
      // hidden
    } else {
      fields.push({
        order: order++,
        field_id: item,
        field_label: item,
        field_type: 'text',
        field_value: data[item],
      });
    }
  }
  fields = fields.sort((a, b) => (a.order > b.order ? 1 : -1));
  return fields;
};

export const resolveMapping = (data) => {
  const response = {};
  for (let i = 0; i < data.fields.length; i++) {
    const element = data.fields[i];
    if (element.field_id === 'conditions') {
      const fields = element.fields.map((field) => {
        const map = {};
        for (let j = 0; j < field.length; j++) {
          const jElement = field[j];
          if (jElement.field_id === 'subcondition') {
            const subfields = jElement.fields.map((subfield) => {
              const submap = {};
              for (let k = 0; k < subfield.length; k++) {
                const kElement = subfield[k];
                submap[kElement.field_id] = kElement.field_value;
              }
              return submap;
            });
            map[jElement.field_id] = subfields;
          } else {
            map[jElement.field_id] = jElement.field_value;
          }
        }
        return map;
      });
      response[element.field_id] = JSON.stringify(fields);
    } else {
      response[element.field_id] = element.field_value;
    }
  }
  return response;
};
