/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import Checkbox from './elements/Checkbox';
import Input from './elements/Input';
import Select from './elements/Select';
import Nested from './elements/Nested';
import {useSelector} from "react-redux";



const Element = ({
  fields, schema, field_type, field_id, parent, ordorNo, field_label, field_placeholder, field_value, field_options, key,
}) => {
  const {
    conditionSummaryList
  } = useSelector((state) => state.auth);

  switch (field_type) {
    case 'text':
      return (
        <>
          <Input
            key={key}
            field_id={parent ? `${parent}/${ordorNo}/${field_id}` : field_id}
            field_label={field_label}
            field_placeholder={field_placeholder}
            field_value={field_value}
          />
        </>
      );
    case 'nested':
      return (
        <>

          <Nested
            key={key}
            schema={schema}
            field_id={parent ? `${parent}/${ordorNo}/${field_id}` : field_id}
            field_label={field_label}
            fields={fields}
            field_type={field_type}
            parent={parent}
            conditionSummaryList={conditionSummaryList}
          />
        </>
      );
    case 'select':
      return (
        <Select
          key={key}
          field_id={parent ? `${parent}/${ordorNo}/${field_id}` : field_id}
          field_label={field_label}
          field_placeholder={field_placeholder}
          field_value={field_value}
          field_options={field_options}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          key={key}
          field_id={parent ? `${parent}/${ordorNo}/${field_id}` : field_id}
          field_label={field_label}
          field_value={field_value}
        />
      );

    default:
      return null;
  }
};

export default Element;
