/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
// import { setLoader } from '@Actions';
import store from '@Store';

export const isHandlerEnabled = (config = {}) => (!(config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled));

export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    // Modify request here
    // store.dispatch(setLoader(true));
  }
  return request;
};

export const successHandler = (response) => {
  if (isHandlerEnabled(response)) {
    // Handle responses
    // store.dispatch(setLoader(false));
  }
  return response;
};

export const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    // store.dispatch(setLoader(false));
    // You can decide what you need to do to handle errors.
    // here's example for unautherized user to log them out .
    // error.response.status === 401 && Auth.signOut();
  }
  return Promise.reject({ ...error });
};
